<template>
  <a href="#" @click.prevent="open">
    <slot>Contact Us</slot>
  </a>
</template>

<script>
export default {
  methods: {
    open () {
      window.Intercom('showNewMessage')
    }
  }
}
</script>
